import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Popconfirm, Select } from 'antd';
import { keys } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { NoSettingIcon } from '../../../assets/svg';
import { PROVIDER_FIELD_TYPE, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import { DELETE_PLATFORM, UPDATE_PLATFORM } from '../graphql/Mutation';
import {
  GET_PLATFORMS,
  GET_PLATFORM_CONFIGS,
  GET_PROVIDER,
  GET_PROVIDERS
} from '../graphql/Queries';

const { Option } = Select;
const { number } = formValidatorRules;

const PlatformList = ({
  providerId,
  setProviderId,
  platformDetail,
  setProviderValue,
  setProviderSlug,
  activeKey
}) => {
  const [form] = Form.useForm();
  const { state } = useContext(AppContext);
  const [platformList, setPlatformList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [platformsLoading, setPlatformsLoading] = useState(true);
  const [platformName, setPlatformName] = useState();
  const [platformId, setPlatformId] = useState();
  const [providerDetail, setProviderDetail] = useState();
  const [platformSlug, setPlatformSlug] = useState();
  const [editPlatform, setEditPlatform] = useState(false);
  const [isEditPlatformBtnLoading, setIsEditPlatformBtnLoading] = useState(
    false
  );
  const [initialValues, setInitialValues] = useState();

  const [executePlatformsList, { loading: isProviderLoading }] = useLazyQuery(
    GET_PLATFORMS,
    {
      onCompleted: (response) => {
        setPlatformList([...platformList, ...response?.platforms?.platforms]);
        setPlatformName(response?.platforms?.platforms?.[0]?.name);
        setPlatformId(response?.platforms?.platforms?.[0]?.id);
        setPlatformSlug(
          keys(response?.platforms?.platforms?.[0]?.platformConfigs?.EMAIL)[0]
        );
        setPlatformsLoading(false);
        setProviderDetail(response?.platforms?.platforms?.[0]?.provider);
      },
      fetchPolicy: 'network-only'
    }
  );

  const [executePlatform, { loading: isPlatFormLoading }] = useLazyQuery(
    GET_PLATFORM_CONFIGS,
    {
      onCompleted: (response) => {
        setInitialValues(response?.getPlatformConfigs?.platformConfigs);
      },
      fetchPolicy: 'network-only'
    }
  );

  const [executeUpdatePlatform] = useMutation(UPDATE_PLATFORM, {
    onError: () => {
      setIsEditPlatformBtnLoading(false);
    }
  });

  const [executeProvider] = useLazyQuery(GET_PROVIDER, {
    onCompleted: (response) => {
      setProviderDetail(response?.provider);
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [executeProvidersList] = useLazyQuery(GET_PROVIDERS, {
    onCompleted: (response) => {
      setProviderList([...providerList, ...response?.providers?.providers]);
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [executeDeletePlatform] = useMutation(DELETE_PLATFORM, {
    onError: () => {}
  });

  useEffect(() => {
    if (state?.projectEnvId && activeKey === ROUTES?.SETTING) {
      setPlatformsLoading(true);
      setPlatformList([]);
      executePlatformsList({
        variables: {
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            sortBy: 'createdAtDESC'
          }
        }
      });
      if (providerList?.length === 0) {
        executeProvidersList({
          variables: {
            filter: {
              skip: 0,
              limit: 10,
              sortBy: 'createdAtDESC',
              type: 'EMAIL'
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, activeKey]);

  useEffect(() => {
    if (state?.projectEnvId && platformList?.length > 0) {
      executePlatform({
        variables: {
          id: platformId,
          type: 'EMAIL'
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, platformId]);

  useEffect(() => {
    if (providerId) {
      executeProvider({
        variables: {
          id: providerId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    if (initialValues) {
      if (providerDetail?.slug) {
        form.setFieldsValue({
          name: platformName,
          platformConfigs: initialValues
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, platformName, editPlatform]);

  const deletePlatformFun = async (id) => {
    const response = await executeDeletePlatform({
      variables: {
        id: id
      }
    });
    if (response?.data) {
      setPlatformsLoading(true);
      setPlatformList([]);
      executePlatformsList({
        variables: {
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            sortBy: 'createdAtDESC'
          }
        }
      });
    }
  };

  const providerConfigs = platformDetail
    ? platformDetail?.[0]?.platformConfigs?.EMAIL?.[platformSlug] || []
    : providerDetail?.providerConfigs || [];

  const updatePlatformFun = async (values) => {
    setIsEditPlatformBtnLoading(true);
    const response = await executeUpdatePlatform({
      variables: {
        data: {
          name: platformName,
          platformConfigs:
            providerDetail?.name === 'DEFAULT'
              ? {
                  EMAIL: { DEFAULT: {} }
                }
              : values?.platformConfigs || {},
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL'
        },
        id: platformId
      }
    });
    if (response?.data) {
      setIsEditPlatformBtnLoading(false);
      executePlatform({
        variables: {
          id: platformId,
          type: 'EMAIL'
        }
      });
      setEditPlatform(false);
    }
  };

  if (platformsLoading) return <LoaderComponent setHeight={50} />;

  return (
    <div className="platform">
      {platformList?.length > 0 && (
        <>
          <LoaderComponent spinning={isProviderLoading || isPlatFormLoading}>
            <div className="add-container setting">
              <div className="d-flex flex-horizontal justify-between">
                <p className="add-title">
                  {!editPlatform ? 'Edit Platform' : 'Update Platform'}
                </p>
              </div>
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="horizontal"
                onFinish={updatePlatformFun}
                labelCol={{
                  span: 3
                }}
                wrapperCol={{
                  span: 21
                }}
              >
                <Form.Item label="Platform Name" name="name" className="mt-10">
                  <Input className="input-box" disabled />
                </Form.Item>
                {providerConfigs?.map((providerConfig) => {
                  if (providerConfig?.type === PROVIDER_FIELD_TYPE?.NUMBER) {
                    return (
                      <Form.Item
                        key={providerConfig?.key}
                        label={providerConfig?.label}
                        name={[
                          'platformConfigs',
                          'EMAIL',
                          providerDetail?.slug,
                          providerConfig?.key
                        ]}
                        className="mt-10"
                        rules={[
                          {
                            required: providerConfig?.isRequired,
                            message: `Please input ${providerConfig?.label}`
                          },
                          number
                        ]}
                      >
                        {providerConfig?.type ===
                        PROVIDER_FIELD_TYPE?.PASSWORD ? (
                          <Input.Password
                            className="customize-password"
                            disabled={!editPlatform}
                          />
                        ) : (
                          <Input
                            className="input-box"
                            disabled={!editPlatform}
                          />
                        )}
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item
                      key={providerConfig?.key}
                      label={providerConfig?.label}
                      name={[
                        'platformConfigs',
                        'EMAIL',
                        providerDetail?.slug,
                        providerConfig?.key
                      ]}
                      className="mt-10"
                      rules={[
                        {
                          required: providerConfig?.isRequired,
                          message: `Please input ${providerConfig?.label}`
                        }
                      ]}
                    >
                      {providerConfig?.type ===
                      PROVIDER_FIELD_TYPE?.PASSWORD ? (
                        <Input.Password
                          className="customize-password"
                          disabled={!editPlatform}
                        />
                      ) : (
                        <Input className="input-box" disabled={!editPlatform} />
                      )}
                    </Form.Item>
                  );
                })}
                <Form.Item
                  wrapperCol={{
                    offset: 3,
                    span: 21
                  }}
                >
                  {!editPlatform ? (
                    providerDetail?.name !== 'DEFAULT' && (
                      <Button
                        type="primary"
                        className="primary-button"
                        onClick={() => setEditPlatform(true)}
                      >
                        <EditOutlined
                          onClick={() => {
                            setProviderSlug(platformSlug);
                          }}
                        />
                        Edit Platform
                      </Button>
                    )
                  ) : (
                    <>
                      <Button
                        className="primary-button"
                        type="primary"
                        onClick={form?.submit}
                        loading={isEditPlatformBtnLoading}
                      >
                        Update Platform
                      </Button>
                      <Button
                        className="primary-button"
                        type="primary"
                        onClick={() => {
                          setEditPlatform(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  <Popconfirm
                    title={`Are you sure to delete ${platformName} ?`}
                    onConfirm={() => deletePlatformFun(platformList?.[0]?.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="secondary-button"
                      type="primary"
                      htmlType="submit"
                    >
                      <DeleteOutlined />
                      Delete Platform
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </Form>
            </div>
          </LoaderComponent>
        </>
      )}
      {platformList?.length === 0 && !platformsLoading && (
        <div className="d-flex flex-vertical align-center">
          <div className="img-section mt-40">
            <NoSettingIcon />
          </div>
          <div className="text-section align-center mt-30">
            <span>
              No Setting Available! Please select the Platform Provider!
            </span>
          </div>
          <div className="provider-dropdown mt-20" id="provider-dropdown">
            <SelectComponent
              className="provider-select"
              showSearch={false}
              allowClear={false}
              placeholder="Select Platform Provider"
              onChange={(e, values) => {
                setProviderSlug(values?.data?.slug);
                setProviderId(values?.key);
                setProviderValue(e);
              }}
            >
              {providerList?.length > 0 &&
                providerList?.map((provider) => {
                  return (
                    <Option
                      key={provider?.id}
                      value={provider?.name}
                      data={provider}
                    >
                      {provider?.name}
                    </Option>
                  );
                })}
            </SelectComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformList;
