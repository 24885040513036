import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import LandingWrapper from '../modules/landing';
import ProjectsWrapper from '../modules/projects';
import UserWrapper from '../modules/users';

const ContentRoutes = () => {
  const redirectToDashboard = () => {
    return <Redirect to={ROUTES?.DASHBOARD} />;
  };

  return (
    <>
      <Switch>
        <Route exact path={ROUTES?.MAIN} component={redirectToDashboard} />
        <Route path={ROUTES?.USERS_MANAGEMENT} component={UserWrapper} />
        <Route path={ROUTES?.LANDING} component={LandingWrapper} />
        <Route path={ROUTES?.PROJECTS} component={ProjectsWrapper} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
