import { Button, Popconfirm } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';

const InviteTeamMembersCard = ({
  inviteTeamMember,
  inviteTeamMemberDeleteHandler,
  setIsInviteMemberModalOpen,
  setInviteTeamMemberDetails
}) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();

  return (
    <div className="member-container">
      <div className="member-header">
        <div className="left-header">
          <p className="member-name">{inviteTeamMember?.email}</p>
        </div>
        <div className="right-header">
          {currentUser?.email !== inviteTeamMember?.email && (
            <>
              <Button
                type="link"
                className="view-member"
                onClick={() => {
                  setIsInviteMemberModalOpen(true);
                  setInviteTeamMemberDetails(inviteTeamMember);
                }}
              >
                Edit User
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${inviteTeamMember?.email}?`}
                onConfirm={() => {
                  inviteTeamMemberDeleteHandler(inviteTeamMember?.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" className="delete-member">
                  Delete User
                </Button>
              </Popconfirm>
            </>
          )}
        </div>
      </div>
      <div className="member-body">
        <div className="body-item">
          <div>
            <p className="item-title">{inviteTeamMember?.permission}</p>
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default InviteTeamMembersCard;
