import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SEND_RAW_EMAIL = gql`
  mutation sendRawEmail($data: SendRawEmailData!, $projectEnvId: ID!) {
    sendRawEmail(data: $data, projectEnvId: $projectEnvId) {
      message
      status
    }
  }
`;
