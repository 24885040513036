import React from 'react';
import Nodata from '../../components/Nodata';
import './analytics.less';

const Analytics = () => {
  return (
    <div className="global-container analytics">
      <div className="text-center analytics-illustration">
        <Nodata />
      </div>
    </div>
  );
};

export default Analytics;
