import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Mutations';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const [executeLogoutUser, { loading, error, data }] = useMutation(
    LOGOUT_USER,
    {
      onError() {}
    }
  );

  useEffect(() => {
    executeLogoutUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  if (error) {
    if (error?.graphQLErrors?.length) {
      const newErrors = error?.graphQLErrors?.[0];
      // eslint-disable-next-line no-console
      console.log('newErrors => ', newErrors);

      const isUnAuthenticated =
        get(newErrors, 'extensions.code') === 'UNAUTHENTICATED' ||
        get(newErrors, 'extensions.exception.name') === 'JsonWebTokenError';
      if (isUnAuthenticated) {
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-undef
        history.push(ROUTES?.LOGIN);
        return null;
      }
    }
    history?.goBack();
    return null;
  }

  if (data) {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    history.push(ROUTES?.LOGIN);
    return null;
  }

  return null;
};

export default Logout;
